import { createStore } from 'vuex'

export default createStore({
  state: {
    accessToken: localStorage.getItem('access_token') || null,
    employeeId: localStorage.getItem('employee_id') || null,
    employeeName: localStorage.getItem('employee_name') || null,
    profileImage: localStorage.getItem('profile_image') || null,
  },
  mutations: {
    setAccessToken(state, token) {
      state.accessToken = token
    },
    setEmployeeId(state, id) {
      state.employeeId = id
    },
    setEmployeeName(state, name) {
      state.employeeName = name
    },
    setProfileImage(state, image) {
      state.profileImage = image
    },
    clearUserData(state) {
      state.accessToken = null
      state.employeeId = null
      state.employeeName = null
      state.profileImage = null
    },
  },
  actions: {
    setAccessToken({ commit }, token) {
      commit('setAccessToken', token)
      localStorage.setItem('access_token', token)
    },
    setEmployeeId({ commit }, id) {
      commit('setEmployeeId', id)
      localStorage.setItem('employee_id', id)
    },
    setEmployeeName({ commit }, name) {
      commit('setEmployeeName', name)
      localStorage.setItem('employee_name', name)
    },
    setProfileImage({ commit }, image) {
      commit('setProfileImage', image)
      localStorage.setItem('profile_image', image)
    },
    logout({ commit }) {
      commit('clearUserData')
      localStorage.removeItem('access_token')
      localStorage.removeItem('employee_id')
      localStorage.removeItem('employee_name')
      localStorage.removeItem('profile_image')
    },
  },
  getters: {
    accessToken: (state) => state.accessToken,
    employeeId: (state) => state.employeeId,
    employeeName: (state) => state.employeeName,
    profileImage: (state) => state.profileImage,
  },
})

// eslint-disable-next-line
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import auth from './auth/auth'
import axios from 'axios'
// import eventBus from './eventBus'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)

app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

// Access token code
axios.defaults.baseURL = 'https://your-instance.salesforce.com'

axios.interceptors.request.use((config) => {
  const accessToken = auth.getters.accessToken
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  return config
})

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      // Access token has expired, redirect to the login page.
      // You can also show a message to the user indicating that they need to log in again.
      // Replace '/login' with the actual path to your login page.
      window.location.href = '/login'
    }
    return Promise.reject(error)
  },
)

app.use(auth)
app.mount('#app')

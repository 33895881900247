// eslint-disable-next-line
import { h, resolveComponent } from 'vue'
// import { createRouter, createWebHashHistory } from 'vue-router'
import { createRouter, createWebHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import store from '@/auth/auth.js'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
        meta: {
          requiresAuth: true,
          title: 'Dashboard',
        },
      },
      {
        path: '/leave-tracker',
        name: 'Leave Tracker',
        // component: DefaultLayout,
        component: () => import('@/views/leave-tracker.vue'),
        // redirect: '/leave-tracker',
        meta: { requiresAuth: true, title: 'Leave Tracker' },
      },
      {
        path: '/profile',
        name: 'Profile',
        // component: DefaultLayout,
        component: () => import('@/views/profile.vue'),
        // redirect: '/leave-tracker',
        meta: { requiresAuth: true, title: 'Profile' },
      },
      {
        path: '/time-tracker',
        name: 'Time Tracker',
        // component: DefaultLayout,
        component: () => import('@/views/time-tracker.vue'),
        // redirect: '/leave-tracker',
        meta: { requiresAuth: true, title: 'Time Tracker' },
      },
      {
        path: '/projects',
        name: 'Projects',
        // component: DefaultLayout,
        component: () => import('@/views/projects.vue'),
        // redirect: '/leave-tracker',
        meta: { requiresAuth: true, title: 'Projects' },
      },
      {
        path: '/holidays',
        name: 'Holidays',
        // component: DefaultLayout,
        component: () => import('@/views/holidays.vue'),
        // redirect: '/leave-tracker',
        meta: { requiresAuth: true, title: 'Holidays' },
      },
      {
        path: '/onboarding-path',
        name: 'Onboarding Path',
        // component: DefaultLayout,
        component: () => import('@/views/onboarding-path.vue'),
        // redirect: '/leave-tracker',
        meta: { requiresAuth: true, title: 'Onboarding Path' },
      },
      {
        path: '/certification',
        name: 'Certification',
        // component: DefaultLayout,
        component: () => import('@/views/Certification.vue'),
        // redirect: '/leave-tracker',
        meta: { requiresAuth: true, title: 'Certification' },
      },

      // {
      //   path: '/theme',
      //   name: 'Theme',
      //   redirect: '/theme/typography',
      // },
      // {
      //   path: '/theme/colors',
      //   name: 'Colors',
      //   component: () => import('@/views/theme/Colors.vue'),
      // },
      // {
      //   path: '/theme/typography',
      //   name: 'Typography',
      //   component: () => import('@/views/theme/Typography.vue'),
      // },
      // {
      //   path: '/base',
      //   name: 'Base',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/base/breadcrumbs',
      //   children: [
      //     {
      //       path: '/base/accordion',
      //       name: 'Accordion',
      //       component: () => import('@/views/base/Accordion.vue'),
      //     },
      //     {
      //       path: '/base/breadcrumbs',
      //       name: 'Breadcrumbs',
      //       component: () => import('@/views/base/Breadcrumbs.vue'),
      //     },
      //     {
      //       path: '/base/cards',
      //       name: 'Cards',
      //       component: () => import('@/views/base/Cards.vue'),
      //     },
      //     {
      //       path: '/base/carousels',
      //       name: 'Carousels',
      //       component: () => import('@/views/base/Carousels.vue'),
      //     },
      //     {
      //       path: '/base/collapses',
      //       name: 'Collapses',
      //       component: () => import('@/views/base/Collapses.vue'),
      //     },
      //     {
      //       path: '/base/list-groups',
      //       name: 'List Groups',
      //       component: () => import('@/views/base/ListGroups.vue'),
      //     },
      //     {
      //       path: '/base/navs',
      //       name: 'Navs',
      //       component: () => import('@/views/base/Navs.vue'),
      //     },
      //     {
      //       path: '/base/paginations',
      //       name: 'Paginations',
      //       component: () => import('@/views/base/Paginations.vue'),
      //     },
      //     {
      //       path: '/base/placeholders',
      //       name: 'Placeholders',
      //       component: () => import('@/views/base/Placeholders.vue'),
      //     },
      //     {
      //       path: '/base/popovers',
      //       name: 'Popovers',
      //       component: () => import('@/views/base/Popovers.vue'),
      //     },
      //     {
      //       path: '/base/progress',
      //       name: 'Progress',
      //       component: () => import('@/views/base/Progress.vue'),
      //     },
      //     {
      //       path: '/base/spinners',
      //       name: 'Spinners',
      //       component: () => import('@/views/base/Spinners.vue'),
      //     },
      //     {
      //       path: '/base/tables',
      //       name: 'Tables',
      //       component: () => import('@/views/base/Tables.vue'),
      //     },
      //     {
      //       path: '/base/tooltips',
      //       name: 'Tooltips',
      //       component: () => import('@/views/base/Tooltips.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/buttons',
      //   name: 'Buttons',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/buttons/standard-buttons',
      //   children: [
      //     {
      //       path: '/buttons/standard-buttons',
      //       name: 'Buttons',
      //       component: () => import('@/views/buttons/Buttons.vue'),
      //     },
      //     {
      //       path: '/buttons/dropdowns',
      //       name: 'Dropdowns',
      //       component: () => import('@/views/buttons/Dropdowns.vue'),
      //     },
      //     {
      //       path: '/buttons/button-groups',
      //       name: 'Button Groups',
      //       component: () => import('@/views/buttons/ButtonGroups.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/forms',
      //   name: 'Forms',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/forms/form-control',
      //   children: [
      //     {
      //       path: '/forms/form-control',
      //       name: 'Form Control',
      //       component: () => import('@/views/forms/FormControl.vue'),
      //     },
      //     {
      //       path: '/forms/select',
      //       name: 'Select',
      //       component: () => import('@/views/forms/Select.vue'),
      //     },
      //     {
      //       path: '/forms/checks-radios',
      //       name: 'Checks & Radios',
      //       component: () => import('@/views/forms/ChecksRadios.vue'),
      //     },
      //     {
      //       path: '/forms/range',
      //       name: 'Range',
      //       component: () => import('@/views/forms/Range.vue'),
      //     },
      //     {
      //       path: '/forms/input-group',
      //       name: 'Input Group',
      //       component: () => import('@/views/forms/InputGroup.vue'),
      //     },
      //     {
      //       path: '/forms/floating-labels',
      //       name: 'Floating Labels',
      //       component: () => import('@/views/forms/FloatingLabels.vue'),
      //     },
      //     {
      //       path: '/forms/layout',
      //       name: 'Layout',
      //       component: () => import('@/views/forms/Layout.vue'),
      //     },
      //     {
      //       path: '/forms/validation',
      //       name: 'Validation',
      //       component: () => import('@/views/forms/Validation.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/charts',
      //   name: 'Charts',
      //   component: () => import('@/views/charts/Charts.vue'),
      // },
      // {
      //   path: '/icons',
      //   name: 'Icons',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/icons/coreui-icons',
      //   children: [
      //     {
      //       path: '/icons/coreui-icons',
      //       name: 'CoreUI Icons',
      //       component: () => import('@/views/icons/CoreUIIcons.vue'),
      //     },
      //     {
      //       path: '/icons/brands',
      //       name: 'Brands',
      //       component: () => import('@/views/icons/Brands.vue'),
      //     },
      //     {
      //       path: '/icons/flags',
      //       name: 'Flags',
      //       component: () => import('@/views/icons/Flags.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/notifications',
      //   name: 'Notifications',
      //   component: {
      //     render() {
      //       return h(resolveComponent('router-view'))
      //     },
      //   },
      //   redirect: '/notifications/alerts',
      //   children: [
      //     {
      //       path: '/notifications/alerts',
      //       name: 'Alerts',
      //       component: () => import('@/views/notifications/Alerts.vue'),
      //     },
      //     {
      //       path: '/notifications/badges',
      //       name: 'Badges',
      //       component: () => import('@/views/notifications/Badges.vue'),
      //     },
      //     {
      //       path: '/notifications/modals',
      //       name: 'Modals',
      //       component: () => import('@/views/notifications/Modals.vue'),
      //     },
      //   ],
      // },
      // {
      //   path: '/widgets',
      //   name: 'Widgets',
      //   component: () => import('@/views/widgets/Widgets.vue'),
      // },
    ],
  },
  // {
  //   path: '/leave-tracker',
  //   name: 'Leave Tracker',
  //   // component: DefaultLayout,
  //   component: () => import('@/views/leave-tracker.vue'),
  //   // redirect: '/leave-tracker',
  // },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/login'),
        meta: { title: 'Login' },
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
      {
        // forget password
        path: '/forget',
        name: 'forget',
        component: () => import('@/views/pages/forget.vue'),
        meta: { title: 'Forget Password' },
      },
      {
        // otp
        path: '/otp/:employeeId',
        name: 'otp',
        component: () => import('@/views/pages/otp.vue'),
        meta: { title: 'OTP' },
      },
      {
        // update
        path: '/update',
        name: 'update',
        component: () => import('@/views/pages/update.vue'),
        meta: { title: 'Update Password' },
      },
    ],
  },
]

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

// Global navigation guard
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  // Check if the route requires authentication
  if (requiresAuth) {
    // Check if the employeeId and accessToken are present
    if (!store.getters.employeeId || !store.getters.accessToken) {
      // Redirect to the login page
      next('/login')
    } else {
      // Continue to the requested route
      next()
    }
  } else {
    // For routes that don't require authentication, proceed as usual
    next()
  }

  // Update the document title based on the route name
  document.title = to.meta.title || 'HRM Cloud-1'
})

export default router
